import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/dayan.png" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/jose-gonzalez.png" // Tell webpack this JS file uses this image

import Footer from "../components/footer"
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/fmv.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
          <div className="texto inter-bold">
             <span className="opacity"> Maneio do rebanho ovino<br></br></span> Reprodução, nutrição e sanidade <br></br>
            
            </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal13} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      José Sainz
                      </div>
                    
                    <div className="titulo inter-light">
                    Consultor de rebanhos de pequenos ruminantes
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                       10 de dezembro, 2024
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      420€ +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/maneio-ovinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                <div className="form">
                <a href="https://forms.gle/x7crtoJUSCAQSWh78" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div>
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotomasterclass} alt="" />
        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Descrição
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
              <div className="desc inter-light">
              Esta formação tem como objetivo oferecer uma visão sobre a
gestão e rebanhos de ovelhas em extensivo, com uma
abordagem 360º, passando pela nutrição, reprodução e
sanidade.
                </div>
                <div className="logos">
                  
                {/* <img src={logo5} alt="" />
                <img src={logo2} alt="" /> */}

                  </div>
            </div>
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
   
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 10/12/2024 - MANHÃ
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Bases fisiológicas de la reproducción:
ciclo sexual, el papel del fotoperiodo,
la nutrición en la reproducción e anestros</span></p>
                                </div> 
                    
                                <div class="modulo">
                                  <p><span className="date">10:00 - 11:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Control hormonal del ciclo reproductivo:
la esponja vaginal y el implante de melatonina</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee-break</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">11:30 - 12:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">La curva de partos, análisis gráfico del estado reproductivo del
rebaño: creación, interpretación y implicaciones de la curva de partos
</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">12:30 - 13:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Confección de calendarios productivos: datos necesarios,
sistemas empleados, recomendaciones prácticas e planificación</span></p>
                                </div>
                                <div class="modulo">
                                     <p><span className="date">13:30 - 15:30</span> | <span className="mod">Almoço</span></p>
                                   </div>
                              
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 1 - 10/12/2024 - TARDE
                            </Accordion.Header>

                            <Accordion.Body>
                               
                               <div class="modulo">
                                   <p><span className="date">15:30 - 16:30</span> | <span class="mod">Módulo 5</span> - <span class="txt">El macho, el 50% del éxito reproductivo:
exámen reproductivo y manejo</span></p>
                                   </div> 
                       
           
                    
                                   <div class="modulo">
                                     <p><span className="date">16:30 - 17:30</span> | <span className="mod">Módulo 6</span> - <span class="txt">Patologia reproducitiva del macho:
patologia genital del macho y de la hembra</span></p>
                                   </div>
                              
                                   <div class="modulo">
                                     <p><span className="date">17:30 - 18:30</span> | <span className="mod">Módulo 7</span> - <span class="txt">Alimentación final de gestación:
la toxemia de gestación, el éxito de la crianza al nacimiento, la
calidad del calostro y recomendaciones prácticas de
alimentación en final de gestación</span></p>
                                   </div>
      
        
                       
   
                                   
                                   </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                                <Accordion.Item eventKey="2">
                                <Accordion.Header>
                            
                            +  Dia 2 - 31/01/2024
                            </Accordion.Header>

                            <Accordion.Body>
                               
                               <div class="modulo">
                                   <p><span className="date">09:00 - 10:00</span> | <span class="mod">Módulo 1</span> - <span class="txt">Patologia reproductiva de la hembra:
toma de muestras, etiologías principales y
control de abortos
em OPU</span></p>
                                   </div> 
                       
           
                                  
                                   <div class="modulo">
                                     <p><span className="date">10:00 - 11:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Causas de desecho de las hembras adultas:
enfermedades caquectizantes</span></p>
                                   </div>
                                   <div class="modulo">
                                     <p><span className="date">11:00 - 11:30</span> | <span className="mod">Coffee-break</span></p>
                                   </div>
                                  
                                   <div class="modulo">
                                     <p><span className="date">11:30 - 12:30</span> | <span className="mod">Módulo 3</span> - <span class="txt">Planes sanitarios en rebaños ovinos:
bases de los programas sanitarios, determinación de las
enfermedades "core" y factores a tener en cuenta
comercial da técnica</span></p>
                                   </div>
                                   <div class="modulo">
                                     <p><span className="date">12:30 - 13:30</span> | <span className="mod">Módulo 4</span> - <span class="txt">Resumen</span></p>
                                   </div>
      
                             
                            
                       
   
                                   
                                   </Accordion.Body>
                               </Accordion.Item>
                          
                             


                    


             
                       
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/maneio-ovinos.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                <div className="form">
                <a href="https://forms.gle/x7crtoJUSCAQSWh78" target="_blank" >

                <button className="btn-form inter-bold">Inscrição</button> 

                </a>
          </div>
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal13} alt="" />

              </div>
              <div className="nome">
                José Sainz
              </div>
              <div className="titulo">
              Consultor de rebanhos de pequenos ruminantes
                </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Médico veterinário, consultor e especialista em rebanhos de
pequenos ruminantes
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Diplomado Europeu pelo European College of Small Ruminant
Health Management
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Professor associado na Universidade de Zaragoza</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Coordenador do Programa Nacional de Redução do Uso de
Antibióticos em Carne e Leite de Ovinos e Caprinos
</p>
             
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
